import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BONUS_TYPES,
  BonusStage,
  LOOTBOXES_ANIMATION_GROUPS_KEY
} from '../../../../services/user/data/user-bonuses.data';
import {UserService} from '../../../../services/user/user.service';
import {LanguageService} from '../../../../services/language/language.service';
import {CommonDataService} from '../../../../services/common-data.service';
import {ModalService} from '../../../../modal-v2/modal.service';
import { Router, RouterLink } from '@angular/router';
import {DEFAULT_TRANSLATES} from '../../../../services/lootbox/lootbox-translates';
import {CommonBonusInterface} from '../common-bonus.interface';
import { TranslatePipe } from '../../../translation/translate.pipe';
import { SafePipe } from '../../../pipes/safe.pipe';
import { BonusTimerComponent } from '../bonus-timer/bonus-timer.component';
import { RouterLinkDirective } from '../../../directives/router-link.directive';
import { NgTemplateOutlet, NgClass, AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import {WindowService} from '../../../../services/window.service';
import {StaticContentService} from '../../../../services/static-content.service';
import {filter, tap} from 'rxjs/operators';
import {LootboxService} from '../../../../services/lootbox/lootbox.service';

@Component({
    selector: 'app-bonus-card',
    templateUrl: './bonus-card.component.html',
    styleUrls: ['./bonus-card.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, RouterLink, RouterLinkDirective, BonusTimerComponent, NgClass, AsyncPipe, DecimalPipe, DatePipe, SafePipe, TranslatePipe]
})
export class BonusCardComponent implements OnInit {

  @Input() bonus: CommonBonusInterface;
  @Input() type: BONUS_TYPES = BONUS_TYPES.WELCOME;
  @Input() buttonTitle: string;

  public bonusTypes = BONUS_TYPES;
  public BonusStage = BonusStage;

  /**
   * Cancel bonus event
   */
  @Output() onCancel: EventEmitter<CommonBonusInterface> = new EventEmitter<CommonBonusInterface>();

  /**
   * Activate bonus event
   */
  @Output() onActivate: EventEmitter<CommonBonusInterface> = new EventEmitter<CommonBonusInterface>();
  constructor(
    public user: UserService,
    public lang: LanguageService,
    public data: CommonDataService,
    private _modal: ModalService,
    private _router: Router,
    private _window: WindowService,
    private _static: StaticContentService,
    private _lootbox: LootboxService
  ) {}

  get defaultTranslates() {
    return DEFAULT_TRANSLATES;
  }


  ngOnInit() {
    this.type = !this.type ? BONUS_TYPES.WELCOME : this.type;
  }

  public async openLootbox(lootbox: any) {
    this._lootbox.getWheelAnimationData().pipe(
      tap( async (data) => {
        const wheelIndex = this._lootbox.getWheelIndex(data, lootbox);
        if (wheelIndex) {
          const component = (await import('../../../../modal-v2/components/lazy/lootbox-modal/lootbox-modal.component')).LootboxModalComponent;

          await this._modal.openLazy(component, {
            template: 'CLEAR',
            data: { lootbox: {...lootbox, wheelIndex} }
          });
        } else {
          let component;
          const lottieInfo = Object.entries(LOOTBOXES_ANIMATION_GROUPS_KEY).find(([key, value]) => lootbox?.group_key.includes(key))?.[1];
          if (lottieInfo) {
            component = (await import('../../../../modal-v2/components/lazy/welcome-lootbox-modal/welcome-lootbox-modal.component')).WelcomeLootboxModalComponent;
          } else {
            component = (await import('../../../../modal-v2/components/lazy/lootbox-modal/lootbox-modal.component')).LootboxModalComponent;
          }

          await this._modal.openLazy(component, {
            template: 'CLEAR',
            data: { lootbox, lottieInfo }
          });
        }
      })
    ).subscribe();
  }

  public navigateToAllGames(games: any[]) {
    if (games && games.length) {
      return this._router.navigate(['/', 'games'],
        {
          queryParams: {
            'external_id[]': games.map(game => game.identifier)
          }
        }
      );
    }
  }

  public onConfirm() {
    this._window.nativeWindow.open(
      `https://${this.user.info.email.split('@')[1]}`, '_blank');

  }
}
